const feathers = require('@feathersjs/feathers');
const rest = require('@feathersjs/rest-client');
const auth = require('@feathersjs/authentication-client');
const axios = require('axios');

const featherApp = feathers();

// Connect to a different URL
export const BACKEND_URL = window._env_.BACKEND_URL;
const restClient = rest(BACKEND_URL)

// console.log(BACKEND_URL)
featherApp
  // .configure(restClient.fetch(window.fetch.bind(window)))
  .configure(restClient.axios(axios))
  .configure(auth({
    storage: window.localStorage
  }))

export default featherApp;
