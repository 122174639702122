import React from "react";
import {Col, Layout, Popover, Row} from 'antd';
import {connect, useDispatch, useSelector} from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import HorizontalNav from "../HorizontalNav";
import {Link, useHistory} from "react-router-dom";
import {switchLanguage, toggleCollapsedSideNav} from "../../../appRedux/actions/Setting";
import UserProfile from "../../Sidebar/UserProfile";

const {Header} = Layout;

export function AppLogo() {
  const {authUser, initURL} = useSelector(({auth}) => auth);

  return (
    <>
      <Link  to={`/profile/${authUser.profileId}`} className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo ">
        <img alt="" src={require("assets/images/logo-tomathien.png")} style={{height:64, objectFit:"contain" }}/>
      </Link>
      <Link  to={`/profile/${authUser.profileId}`} className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
        <img alt="" src={require("assets/images/logo-tomathien.png")} style={{width: 120, maxWidth: "unset"}}/>
      </Link>
    </>
  );
};
const InsideHeader = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector(({settings}) => settings.locale);
  const {navCollapsed} = useSelector(({common}) => common);

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);


  return (<div className="gx-header-horizontal gx-inside-header-horizontal gx-bg-white">
    <Header className="gx-header-horizontal-main">
      <div className="gx-container gx-w-100">
        <Row gutter={1} style={{height: 120}} align={"middle"}>
          <Col xl={0} lg={0} md={3} sm={3} xs={3}>
            <i className="gx-icon-btn icon icon-menu"
               onClick={() => {
                 dispatch(toggleCollapsedSideNav(!navCollapsed));
               }}
            />
          </Col>
          <Col xl={2} lg={2} md={9} sm={9} xs={9}>
            <AppLogo/>
          </Col>
          <Col xl={14} lg={14} md={0} sm={0} xs={0}>
            <HorizontalNav/>
          </Col>
          {/*<Col xl={8} lg={8} md={12} sm={12} xs={12}>*/}
            {/*<ul className="gx-header-notifications gx-ml-auto gx-float-right">*/}
              {/*<li className="gx-language gx-mr-0">*/}
              {/*  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"*/}
              {/*           content={languageMenu()} trigger="click">*/}
              {/*<span className="gx-pointer gx-flex-row gx-align-items-center">*/}
              {/*  <i className={`flag flag-24 flag-${locale.icon}`}/>*/}
              {/*</span>*/}
              {/*  </Popover>*/}
              {/*</li>*/}
              {/*<li className="gx-user-nav gx-ml-2"><UserProfile/></li>*/}
            {/*</ul>*/}
          {/*</Col>*/}
        </Row>

      </div>
    </Header>
  </div>);
};

const mapStateToProps = ({settings, common}) => {
  const {navCollapsed} = common;
  const {locale} = settings;
  return {locale, navCollapsed}
};
export default connect(mapStateToProps, {toggleCollapsedSideNav, switchLanguage})(InsideHeader);
