import React from 'react';
import {DatePicker} from "antd";
import moment from "moment";
import {ProForm} from "@ant-design/pro-form";

const CustomDatePicker = ({value, onChange, format, className, readonly,picker, placeholder}) => {
  const dateValue = value && moment(value, format);
  return readonly ? <span>{value}</span> :
    <DatePicker 
      picker={picker} 
      value={dateValue} 
      format={format} 
      placeholder={placeholder}
      onChange={value => {
                  onChange(value?.format(format))}} 
      className={className} style={{width: '100%'}}
    />
}
export const ProDatePicker = props => {
  let {width, className, format = "DD/MM/YYYY", readonly ,picker,placeholder } = props;
  width && (className = `${className} pro-field pro-field-${width}`)
  return <ProForm.Item 
            {...props} 
            className={className}
          >
            {<CustomDatePicker 
              format={format} 
              picker={picker}
              readonly={readonly}
              placeholder = {placeholder}
            />}
          </ProForm.Item>
}
export default ProDatePicker;
