import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, message, Row} from "antd";
import IntlMessages from "../util/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import CircularProgress from "../components/CircularProgress";
import {resetPassword, showAuthLoader} from "../appRedux/actions";
import AuthLayout from "./AuthLayout";
import {ArrowLeftOutlined, BackwardOutlined, RollbackOutlined} from "@ant-design/icons";

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const { requestSuccess } = useSelector(({ auth }) => auth);

 
const handleResetPassword =  (values) => {
    dispatch(showAuthLoader());
    dispatch(resetPassword(values.email));
};

  return <>
    {requestSuccess ? <div>
      <p><IntlMessages id={"message.requestResetPassSent"}/></p>
      <Link to="/signin"><IntlMessages id="app.userAuth.signIn"/></Link>
    </div> : <Form
      name="basic"
      onFinish={ handleResetPassword }
      className="gx-signin-form gx-form-row0">
      <p className={"gx-text-black "}><IntlMessages id={"app.userAuth.forgot"}/></p>
      <Form.Item
        rules={[{required: true, type: 'email', message: <IntlMessages id="message.email"/>,}]}
        name="email">
        <Input placeholder="Email"/>
      </Form.Item>
   
      <Form.Item>
        <Button type="primary" className="gx-mb-0" htmlType="submit"   block>
          <IntlMessages id="app.userAuth.send"/>
        </Button>
      </Form.Item>


        <Link to="/signin"><div className={'gx-return-signin'}><ArrowLeftOutlined style={{marginRight:'3px'}}/><IntlMessages id="app.userAuth.returnSignIn"/></div></Link>

      {/*</Row>*/}
    </Form>}</>
}

const ForgotPassword = () => {
  return (
    <AuthLayout title={<><IntlMessages id="app.userAuth.forgotPassword"/>?</>} formInput={<ForgotPasswordForm/>}/>
  );
};

export default ForgotPassword;
