import React from "react";
import {Menu, Row} from "antd";

import CustomScrollbars from "util/CustomScrollbars";
import {NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR} from "../../constants/ThemeSetting";
import {useSelector} from "react-redux";
import {useNavigationItems} from "../Topbar/HorizontalNav";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import SidebarLogo from "./SidebarLogo";
import {AppLogo} from "../Topbar/InsideHeader";
import {Link} from "react-router-dom";
import {BranchName} from "../Topbar/BelowHeader";


const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

  let {navStyle, themeType} = useSelector(({settings}) => settings);
  let {pathname} = useSelector(({common}) => common);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const items=useNavigationItems()
  return (
    <>
      <BranchName fontSize={14}/>
      <UserProfile showName/>
      <div className="gx-sidebar-content gx-mt-2">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            // theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline" items={items} />
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

