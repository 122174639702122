import {createContext, useContext} from 'react';
import { createContextualCan } from '@casl/react';
import { AppAbility } from './configAbility';
import { useAbility } from '@casl/react';
export const AbilityContext = createContext(AppAbility);

export default createContextualCan(AbilityContext.Consumer);

export const useAppAbility= ()=>{
  const ability = useAbility(AbilityContext);
  return ability
}
