import enLang from "./entries/en-US";
import viLang from "./entries/vi-Vi";
import arLang from "./entries/ar_SA";
import itLang from "./entries/it_IT";
import esLang from "./entries/es_ES";
import frLang from "./entries/fr_FR";

const AppLocale = {
  en: enLang,
  vi: viLang,
  ar: arLang,
  it: itLang,
  es: esLang,
  fr: frLang
};

export default AppLocale;
