import React from 'react';
import {message} from "antd";

export const checkImageType = (file) => {
  const allowedTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
    // 'image/svg+xml',
    'image/webp',
    'image/heic',
    'image/heif',
  ];
  const isTypeAllowed = allowedTypes.includes(file.type);

  if (!isTypeAllowed) {
    message.error('Chỉ được tải lên các tệp tin ảnh!');
  }

  return isTypeAllowed;
};
