const languageData = [
  {
    languageId: 'vietnamese',
    locale: 'vi',
    name: 'Tiếng Việt',
    icon: 'vn'
  },

  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },

];
export default languageData;
