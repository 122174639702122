import {message} from "antd";

const forceLogout =()=>{
  localStorage.removeItem("authUser")
  localStorage.removeItem("feathers-jwt")
  window.location = "/signin"
}
export const withErrorHandler = (func) => {
  return async (...params) => {
    try {
      return await func(...params);
    } catch (e) {
      console.error(e)
      if (e.name == "NotAuthenticated") {
        forceLogout();
      }
      throw e;
    }
  };
}
