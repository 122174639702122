import featherApp from "../../feathersjs/feathersjs";
import {authenticate} from '@feathersjs/authentication'
import _ from "lodash";
import {withErrorHandler} from "./common";

const profiles = featherApp.service("profiles")
export const fetchProfile = withErrorHandler(async (userId) => {
  return userId && await profiles.get(userId);
})


export const updateProfile = withErrorHandler(async (userId, data) => {
  return profiles.patch(userId, data);
})

export const createProfile = withErrorHandler(async (data) => {
  return profiles.create(data);
})

export const sortMapping = {
  descend: -1,
  ascend: 1
}

export const fetchAllProfiles = withErrorHandler(async (searchData) => {
  return await profiles.find(searchData)
})

