export const universitiesInHCM = [
    'Đại học An ninh Nhân dân',
    'Đại học Bách khoa – Đại học Quốc gia TPHCM',
    'Đại học Cảnh sát Nhân dân',
    'Đại học Công nghệ Sài Gòn',
    'Đại học Công nghệ TPHCM',
    'Đại học Công nghệ Thông tin – Đại học Quốc gia TPHCM',
    'Đại học Công thương TPHCM',
    'Đại học Công nghiệp TPHCM',
    'Đại học Fulbright Việt Nam',
    'Đại học FPT TPHCM',
    'Đại học Gia Định',
    'Đại học Giao thông Vận tải Cơ sở 2',
    'Đại học Giao thông Vận tải TPHCM',
    'Đại học GreenWich',
    'Đại học Hoa Sen',
    'Đại học Hùng Vương TPHCM',
    'Đại học Khoa học Tự nhiên – Đại học Quốc gia TPHCM',
    'Đại học Khoa học Xã hội và Nhân văn – Đại học Quốc gia TPHCM',
    'Đại học Kiến trúc TPHCM',
    'Đại học Kinh tế – Tài chính TPHCM',
    'Đại học Kinh tế – Luật – Đại học Quốc gia TPHCM',
    'Đại học Kinh tế TPHCM',
    'Đại học Lao động – Xã hội cơ sở II',
    'Đại học Luật TPHCM',
    'Đại học Mở TPHCM',
    'Đại học Mỹ thuật TPHCM',
    'Đại học Ngân hàng TPHCM',
    'Đại học Ngoại ngữ – Tin học TPHCM',
    'Đại học Ngoại thương Cơ sở 2',
    'Đại học Nguyễn Tất Thành',
    'Đại học Nông lâm TPHCM',
    'Đại học Nội vụ Hà Nội Phân hiệu TPHCM',
    'Đại học Quản lý và Công nghệ TPHCM',
    'Đại học Quốc tế – Đại học Quốc gia TPHCM',
    'Đại học Quốc tế Hồng Bàng',
    'Đại học Quốc tế Sài Gòn',
    'Đại học RMIT Nam Sài Gòn',
    'Đại học Sài Gòn',
    'Đại học Sân khấu Điện ảnh TPHCM',
    'Đại học Sư phạm Kỹ thuật TPHCM',
    'Đại học Sư phạm TPHCM',
    'Đại học Sư phạm Thể dục Thể thao TPHCM',
    'Đại học Tài chính – Marketing',
    'Đại học Tài nguyên và Môi trường TPHCM',
    'Đại học Thể dục Thể thao TPHCM',
    'Đại học Thủy lợi Cơ sở 2',
    'Đại học Tôn Đức Thắng',
    'Đại học Văn Hiến',
    'Đại học Văn hóa TPHCM',
    'Đại học Văn Lang',
    'Đại học Y Dược TPHCM',
    'Đại học Y khoa Phạm Ngọc Thạch',
    'Nhạc viện Thành phố Hồ Chí Minh',
    'Học viện Bưu chính viễn thông cơ sở 2',
    'Học viện Cán Bộ Thành phố Hồ Chí Minh',
    'Học viện Hàng không Việt Nam',
    'Học viện Hành chính Quốc gia Phân hiệu TPHCM',
    'Học viện Kỹ thuật Mật mã Phân hiệu TPHCM',
    'Học viện Phụ nữ Phân hiệu TPHCM',
    'Học viện Thanh thiếu niên Việt Nam phân viện miền Nam',
    'Học viện Tư pháp Cơ sở TPHCM',
    'Trường Cao đẳng An ninh mạng iSPACE',
    'Trường Cao đẳng Bách khoa Nam Sài Gòn',
    'Trường Cao đẳng Bách khoa Sài Gòn',
    'Trường Cao đẳng Bán công Công nghệ và Quản trị doanh nghiệp',
    'Trường Cao đẳng Bình Minh Sài Gòn',
    'Trường Cao đẳng Công nghệ Sài Gòn',
    'Trường Cao đẳng Công nghệ thông tin TP.HCM',
    'Trường Cao đẳng Công nghệ TP.HCM',
    'Trường Cao đẳng Công nghệ Thủ Đức',
    'Trường Cao đẳng Công thương TP.HCM',
    'Trường Cao đẳng Du lịch Sài Gòn',
    'Trường Cao đẳng Đại Việt Sài Gòn',
    'Trường Cao đẳng Điện lực TP.HCM',
    'Trường Cao đẳng FPT Polytechnic – Cơ sở TPHCM',
    'Trường Cao đẳng Giao thông vận tải Đường thủy II',
    'Trường Cao đẳng Giao thông vận tải Trung ương III',
    'Trường Cao đẳng Giao thông Vận tải Trung ương VI',
    'Trường Cao đẳng Giao thông Vận tải TP.HCM',
    'Trường Cao đẳng Hàng hải II',
    'Trường Cao đẳng Kiến trúc - Xây dựng TP.HCM',
    'Trường Cao đẳng Kinh tế - Công nghệ TP.HCM',
    'Trường Cao đẳng Kinh tế Đối ngoại',
    'Trường Cao đẳng Kinh tế Kỹ thuật TP. Hồ Chí Minh',
    'Trường Cao đẳng Kinh tế - Kỹ thuật Thủ Đức',
    'Trường Cao đẳng Kinh tế TP.HCM',
    'Trường Cao đẳng Kỹ nghệ II',
    'Trường Cao đẳng Kỹ thuật Cao Thắng',
    'Trường Cao đẳng Kỹ thuật - Du lịch Sài Gòn',
    'Trường Cao đẳng Kỹ thuật Nguyễn Trường Tộ',
    'Trường Cao đẳng Lương thực - Thực phẩm, Phân hiệu tại TPHCM',
    'Trường Cao đẳng Lý Tự Trọng thành phố Hồ Chí Minh',
    'Trường Cao đẳng Miền Nam',
    'Trường Cao đẳng nghề Thành phố Hồ Chí Minh',
    'Trường Cao đẳng Nông nghiệp Nam Bộ - Phân hiệu thành phố Hồ Chí Minh',
    'Trường Cao đẳng NOVA',
    'Trường Cao đẳng Phát thanh Truyền hình 2',
    'Trường Cao đẳng Quốc tế Tp. Hồ Chí Minh',
    'Trường Cao đẳng Quốc tế KENT',
    'Trường Cao đẳng Quốc tế Sài Gòn - Cơ sở TPHCM',
    'Trường Cao đẳng Sài Gòn',
    'Trường Cao đẳng Sài Gòn Gia Định',
    'Trường Cao đẳng Thủ Thiêm - Tp. Hồ Chí Minh',
    'Trường Cao đẳng Văn hóa Nghệ thuật TP.HCM',
    'Trường Cao đẳng Văn hóa Nghệ thuật và Du lịch Sài Gòn',
    'Trường Cao đẳng Văn Lang Sài Gòn',
    'Trường Cao đẳng Viễn Đông',
    'Trường Cao đẳng Việt Mỹ',
    'Trường Cao đẳng Xây dựng Tp. Hồ Chí Minh',
    'Trường Cao đẳng Y Dược Hồng Đức',
    'Trường Cao đẳng Y Dược Sài Gòn',
    'Trường Cao đẳng Y khoa Phạm Ngọc Thạch - Cơ sở TP.HCM',
    'Trường Cao đẳng Công nghệ và Du lịch',
    'Trường Cao đẳng Việt Nam Singapore',
    'Trường Cao đẳng Công Thương Việt Nam'
  ];

 export const universityOptions = universitiesInHCM.map(uni => ({
    label: uni,
    value: uni
  }));