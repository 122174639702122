import React, {useEffect} from "react";
import {Button, Col, Form, Input, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {hideMessage, showAuthLoader, userSignIn} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import AuthLayout from "./AuthLayout";

const LoginForm = ({setEnableForgot}) => {

  const dispatch = useDispatch();
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (<Form
    name="basic"
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    className="gx-signin-form gx-form-row0">
    <Form.Item
      rules={[{required: true, type: 'email', message: <IntlMessages id="message.email"/>,}]}
      name="email">
      <Input size={'large'} placeholder="Email"/>
    </Form.Item>
    <Form.Item
      rules={[{required: true, message: <IntlMessages id="message.password"/>,}]}
        name="password">
      <Input.Password  size={'large'} type="password" placeholder="Mật khẩu"/>
    </Form.Item>

    <Form.Item>
      <Button type="primary"  htmlType="submit" block>
        <IntlMessages id="app.userAuth.signIn"/>
      </Button>


    </Form.Item>
    <Row justify={'space-between'}>
      <Col >
          <Link to="/forgot-password"><IntlMessages id={"app.userAuth.forgotPassword"}/></Link>
      </Col>
      <Col >
        <Link to="/signup"><IntlMessages id="app.userAuth.signUp"/></Link>
      </Col>
    </Row>

  </Form>)
}
const SignIn = () => {

  const dispatch = useDispatch();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);
  const history = useHistory();
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/home');
    }
  });

  return (
    <AuthLayout title={<><IntlMessages id="app.userAuth.signIn"/></>} formInput={<LoginForm/>} ignoreRequestSuccess/>
  );
};

export default SignIn;
