const dioceses = [
    'Tổng giáo phận Hà Nội',
    'Giáo phận Bắc Ninh',
    'Giáo phận Bùi Chu',
    'Giáo phận Hà Tĩnh',
    'Giáo phận Hải Phòng',
    'Giáo phận Hưng Hóa',
    'Giáo phận Lạng Sơn và Cao Bằng',
    'Giáo phận Phát Diệm',
    'Giáo phận Thái Bình',
    'Giáo phận Thanh Hóa',
    'Giáo phận Vinh',
    'Tổng Giáo phận Huế',
    'Giáo phận Ban Mê Thuột',
    'Giáo phận Đà Nẵng',
    'Giáo phận Kon Tum',
    'Giáo phận Nha Trang',
    'Giáo phận Qui Nhơn',
    'Tổng Giáo phận TP.HCM',
    'Giáo phận Bà Rịa',
    'Giáo phận Cần Thơ',
    'Giáo phận Đà Lạt',
    'Giáo phận Long Xuyên',
    'Giáo phận Mỹ Tho',
    'Giáo phận Phan Thiết',
    'Giáo phận Phú Cường',
    'Giáo phận Vĩnh Long',
    'Giáo phận Xuân Lộc'
  ];
  
export  const dioceseOptions = dioceses.map(dio => ({
    label: dio,
    value: dio
  }));
  