import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Form, message, Input} from "antd";
import {confirmResetPassword, resetPassword} from "../appRedux/actions";
import IntlMessages from "../util/IntlMessages";
import AuthLayout from "./AuthLayout";
import {Link, useLocation, useParams} from "react-router-dom";

export const useTokenInfo=()=>{
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  return token
}
const ResetPassForm = () => {
  const dispatch = useDispatch();
  const token = useTokenInfo();
  const {requestSuccess} = useSelector(({auth}) => auth);

  return <>{<Form
    name="basic"
    onFinish={values => {
      const {password, confirmPassword} = values
      if (password !== confirmPassword) {
        message.error("Mật khẩu không khớp với xác nhận!")
        return;
      }
      dispatch(confirmResetPassword({token,password}))
    }
    }
    className="gx-signin-form gx-form-row0">
    <p><IntlMessages id={"appModule.enterPasswordReset"}/></p>
    <Form.Item name={"password"} rules={[{required: true, message: <IntlMessages id="message.password"/>}]} >
      <Input.Password placeholder={"Mật khẩu mới"} />
    </Form.Item>
    <Form.Item name={"confirmPassword"} rules={[{required: true, message: <IntlMessages id="message.password"/>}]} >
      <Input.Password placeholder={"Xác nhận mật khẩu"}/>
    </Form.Item>
    <Form.Item>
      <Button type="primary" className="gx-mb-0" htmlType="submit">
        <IntlMessages id="app.userAuth.resetPassword"/>
      </Button>
    </Form.Item>
  </Form>}</>
}

const ResetPassword = () => {
  return (
    <AuthLayout title={<><IntlMessages id="app.userAuth.resetPassword"/></>} formInput={<ResetPassForm/>} requestSuccessMessage={<div>
      <p><IntlMessages id={"app.userAuth.updatePasswordSuccessfully"}/></p>
      <Link to="/signin"><IntlMessages id="app.userAuth.signIn"/></Link>
    </div>}/>
  );
}

export default ResetPassword;
