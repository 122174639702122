import zhMessages from "../locales/vi_Vi.json";
import antdVi from "antd/lib/locale-provider/vi_VN";

console.log(antdVi)
const viLan = {
  messages: {
    ...zhMessages
  },
  antd: antdVi,
  locale: 'vi-VN'
};
export default viLan;
