import {call,put} from "redux-saga/effects";
import {fetchError, fetchStart, fetchSuccess} from "../actions/Common";
import axios from "axios";
import {notification} from "antd";

export function* execute(url, method, requestData, responseType='json') {
  try {
    yield  put(fetchStart());
    const {data} = yield call(axios, {
      method,
      url,
      data: requestData,
      responseType
    });
    yield  put(fetchSuccess());
    return {data};
  } catch (error) {
    yield  put(fetchError(error));
    return {error};
  }
}

function generateProperties(method, url, requestData, responseType, token) {
  return {
    method,
    url,
    data: requestData,

    responseType,
    // headers: {
    //   Authorization: `Bearer ${token}`
    // },
    withCredentials: true
  };
}

export async function callApi(url, method, requestData, responseType = 'json', ignoreMessage = false) {
  // const token = keycloak.token;
  try {
    return await axios(generateProperties(method, url, requestData, responseType));
  } catch (error) {
    notification.error({message:error.message,description:error.response?.data?.description})
    return {error};
  }
}


export async function get(url) {
  let {data, error} = await callApi(url, "GET");
  if (data) {
    return data
  }
}

export async function post(url,requestData) {
  return await update(url, "POST",requestData);
}


async function update(url,method, requestData) {
  let {data, error} = await callApi(url, method, requestData);
  if (data) {
    return data
  }
}
export async function putRequest(url,requestData) {
  return await update(url, "PUT",requestData);
}

