import React, {useEffect} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {changePassword, showAuthLoader} from "../appRedux/actions";

const ChangePassword = ({isChangePwd, closeModal}) => {
  const {authUser, requestSuccess, loader} = useSelector(({auth}) => auth);;

  const dispatch = useDispatch();

  const onFinish = (value) => {
    const values = {...value, email:authUser.email}
    dispatch(showAuthLoader());
    dispatch(changePassword(values))
  }

  useEffect(() => {
    if(requestSuccess === true)
      closeModal()
  }, [requestSuccess]);

  return (

    <Modal open={isChangePwd}
           footer={null}
           width={600}
           onCancel={() => closeModal()}
           destroyOnClose>
      <h2>Đổi mật khẩu</h2>
      <Form
        name="basic"
        labelCol={{span: 8,}}
        wrapperCol={{span: 16,}}
        style={{maxWidth: 600,}}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Mật khẩu hiện tại"
          name="currentPwd"
          rules={[{required: true, message: 'Vui lòng nhập mật khẩu cũ!',},]}
        >
          <Input.Password/>
        </Form.Item>

        <Form.Item
          name="newPwd"
          label="Mật khẩu mới"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu mới!',
            },
          ]}
          hasFeedback
        >
          <Input.Password/>
        </Form.Item>

        <Form.Item
          name="confirmPwd"
          label="Xác nhận mật khẩu"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Vui lòng xác nhận mật khẩu!',
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPwd') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Mật khẩu mới không khớp!'));
              },
            }),
          ]}
        >
          <Input.Password/>
        </Form.Item>

        <Form.Item wrapperCol={{offset: 8, span: 16,}}>
          <Button type="primary" htmlType="submit" loading={loader}>
            Đổi mật khẩu
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePassword;
