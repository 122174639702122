import React, {useEffect} from "react";
import {Button, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {hideMessage, showAuthLoader, userSignIn} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import AuthLayout from "./AuthLayout";
import {useTokenInfo} from "./ResetPassword";
import {confirmEmail} from "../appRedux/actions";

const ConfirmEmail = () => {

  const dispatch = useDispatch();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);
  const history = useHistory();
  const token = useTokenInfo();
  const {requestSuccess} = useSelector(({auth}) => auth);

  useEffect(() => {
    token && dispatch(confirmEmail(token))
  }, []);

  return (
    <AuthLayout title={<><IntlMessages id="app.userAuth.confirmEmail"/></>}
                formInput={<div>
                  {token}
                </div>} requestSuccessMessage={<div>
      <p><IntlMessages id={"app.userAuth.confirmEmailSuccess"}/></p>
      <Link to="/signin"><IntlMessages id="app.userAuth.signIn"/></Link>
    </div>}/>
  );
};

export default ConfirmEmail;
