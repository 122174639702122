import React from "react";
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import {Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import store, {history} from './appRedux/store';
// import "./firebase/firebase";
import App from "./containers/App/index";
import {AbilityContext} from 'util/Can'
import {initAppAbility} from "./util/configAbility";

// const store = configureStore(/* provide initial state if any */);
const ability = initAppAbility('member');

const NextApp = () =>
  <Provider store={store}>
    <AbilityContext.Provider value={ability}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App}/>
        </Switch>
      </ConnectedRouter>
    </AbilityContext.Provider>
  </Provider>;


export default NextApp;


