import React, {useEffect} from "react";
import {Button, Col, Form, Input, Row, Space} from "antd";
import {Link, useHistory} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {hideMessage, showAuthLoader, userSignUp} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import AuthLayout from "./AuthLayout";

const FormItem = Form.Item;

const SignUp = (props) => {


  const dispatch = useDispatch();
  const history = useHistory();
  const {loader, alertMessage, requestSuccess, showMessage, authUser} = useSelector(({auth}) => auth);


  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/home');
    }
  });


  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    dispatch(showAuthLoader());
    dispatch(userSignUp(values));
  };

  return (<AuthLayout title={<IntlMessages id="app.userAuth.signUp"/>}
                      formInput={
                        <Form
                          initialValues={{remember: true}}
                          name="basic"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          >

                          <Row gutter={16}>
                            <Col span={12}>
                              <FormItem rules={[{required: true, message: <IntlMessages id="label.messRule"/>}]}
                                        name="holyName">
                                <Input placeholder="Tên thánh"/>
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem rules={[{required: true, message: <IntlMessages id="label.messRule"/>}]}
                                        name="lastName">
                                <Input placeholder="Họ"/>
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem rules={[{required: true, message: <IntlMessages id="label.messRule"/>}]}
                                        name="middleName">
                                <Input placeholder="Tên Đệm"/>
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem rules={[{required: true, message: <IntlMessages id="label.messRule"/>}]}
                                        name="firstName">
                                <Input placeholder="Tên"/>
                              </FormItem>
                            </Col>
                            <Col span={24}>
                              <FormItem name="email" rules={[{
                                required: true, type: 'email', message: <IntlMessages id="label.messRule"/>,
                              }]}>
                                <Input placeholder="Email"/>
                              </FormItem>
                            </Col>
                            <Col span={24}>
                              <FormItem name="password"
                                        rules={[{required: true, message: <IntlMessages id="label.messRule"/>}]}>
                                <Input.Password placeholder="Mật khẩu" />
                              </FormItem>
                            </Col>

                            <Col span={24}>
                              <Form.Item
                                name="confirm"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: <IntlMessages id="label.messRule"/>,
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error('Mật khẩu bạn xác nhận nhập không khớp!'));
                                    },
                                  }),
                                ]}
                              >
                                <Input.Password placeholder="Xác nhận mật khẩu" />
                              </Form.Item>
                            </Col>


                            <Col span={24}>
                              <FormItem>
                                <Button type="primary" htmlType="submit" block>
                                  <IntlMessages id="app.userAuth.signUp"/>
                                </Button>
                              </FormItem>
                            </Col>

                          </Row>

                          <Row justify={'space-between'}>
                            <Col><Link to="/forgot-password"><IntlMessages
                              id={"app.userAuth.forgotPassword"}/></Link></Col>
                            <Col><Link to="/signin"><IntlMessages id="app.userAuth.signIn"/></Link></Col>
                          </Row>
                        </Form>}
                      requestSuccessMessage={<IntlMessages id={"app.userAuth.signUpSuccessMessage"}/>}
    />
  );
};

export default SignUp;
