import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../../firebase/firebase";
import {
  CHANGE_PASSWORD,
  CONFIRM_EMAIL,
  CONFIRM_RESET_PASSWORD,
  FETCH_SIGN_IN_USER_PROFILE,
  RESET_PASSWORD,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "../../constants/ActionTypes";
import {
  changePwdSuccess,
  fetchSignInUserProfile,
  fetchSignInUserProfileSuccess,
  sendRequestSuccess,
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess, userSignUpSuccess
} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess
} from "../actions/Auth";
import {fetchProfile} from "../model/profile";
import featherApp from "../../feathersjs/feathersjs";
import {message} from "antd";

const passwordService = featherApp.service("auth-management");
const createUserWithEmailPasswordRequest = async (email, password, lastName, middleName, firstName) => {
  return featherApp.service("users").create({email, password, lastName, middleName, firstName})
    // return featherApp.register(email, password, name)
    //   // await auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);
}

const signInUserWithEmailPasswordRequest = async (email, password) => {
  // return await aw.createSession(email, password)
  //   .then(authUser => authUser)
  //   .catch(error => error);
  return featherApp.authenticate({
    strategy: 'local',
    email,
    password
  }).then((auth) => {
    return auth.user
  }).catch(e => {
    console.error('Authentication error', e);
    return e;
  })
}

const sendRecovery = async (email) => {
  try {
    return await passwordService.create({
      action: "sendResetPwd",
      value: { email },
    });
  } catch (error) {
    console.error(error);
    if (error.message === "before hook for 'patch' method returned invalid hook object") {
      return; 
    }

    const errorMessage = error.message === "User not found."
      ? "Email chưa được đăng ký tài khoản!"
      : "Đã xảy ra lỗi, vui lòng thử lại.";

    return { message: errorMessage, originalError: error };
  }
}

const changePwdRequest = async (payload) => {
  return passwordService.create({
    action: "passwordChange",
    value: {
      user: {email: payload.email},
      oldPassword: payload.currentPwd,
      password: payload.newPwd,
    },
  }).then(authUser => authUser).catch(error => error
  );
}

const confirmRecovery = async (info) => {
  return passwordService.create({
    action: "resetPwdLong",
    value: info
  }).then(authUser => authUser).catch(error => error);
}

const verifyEmail = async (token) => {
  return passwordService.create({
    action: "verifySignupLong",
    value: token
  }).then(authUser => authUser).catch(error => error);
}


const signOutRequest = async () => {
  await featherApp.logout()
    .then(authUser => authUser)
    .catch(error => error);
}


const signInUserWithGoogleRequest = async () =>
  await auth.signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await auth.signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await auth.signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await auth.signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({payload}) {
  const {email, password, lastName, middleName, firstName} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password, lastName, middleName, firstName);
    if (signUpUser.message) {
      let message = signUpUser.errors?.[0].message;
      if (message == "email must be unique") {
        message = `Email ${email} đã tồn tại, vui lòng chọn email khác hoặc sử dụng tính năng quên mật khẩu`
      }
      yield put(showAuthMessage(message));
    } else {
      yield put(userSignUpSuccess(email))
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage('Your request has been canceled.'));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
function* handleSuccess(signInUser) {
  yield put(userSignInSuccess(signInUser));
  // yield put(fetchSignInUserProfile(signInUser.profileId));
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.message) {
      console.log(signInUser)
      let message = signInUser.message;
      if (message == "Invalid login") {
        message = `Đã xảy ra lỗi, vui lòng kiểm tra lại email và mật khẩu`
      }
      yield put(showAuthMessage(message));
    } else {
      yield  handleSuccess(signInUser)
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* sendAuthRequest(action, ...params) {
  try {
    const res = yield  call(action, ...params)
    if (res.message) {
      yield put(showAuthMessage(res.message));
    } else {
      yield put(sendRequestSuccess());
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* sendRecoveryEmail({payload}) {
  yield sendAuthRequest(sendRecovery, payload)
}

function* confirmRecoveryPass({payload}) {
  yield sendAuthRequest(confirmRecovery, payload)
}

function* confirmChangePass({payload}) {
  const res = yield changePwdRequest(payload);
  if (res.message) {
      let message = res.message;
      if (message == "Current password is incorrect.") {
        message = `Mật khẩu hiện tại không đúng`
      }
    yield put(showAuthMessage(message));
  } else {
    yield put(changePwdSuccess());
  }

}

function* confirmEmailExist({payload}) {
  yield sendAuthRequest(verifyEmail, payload)
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

function* resetUserPassword() {
  yield takeEvery(RESET_PASSWORD, sendRecoveryEmail)
}

function* changePassword() {
  yield takeEvery(CHANGE_PASSWORD, confirmChangePass)
}

function* confirmResetPassword() {
  yield takeEvery(CONFIRM_RESET_PASSWORD, confirmRecoveryPass)
}

function* confirmEmail() {
  yield takeEvery(CONFIRM_EMAIL, confirmEmailExist)
}

function fetchUserRole(userId) {
  // return aw.getUserRoles(userId)
}

function* fetchUserProfile({payload}) {
  const data = yield call(fetchProfile, payload)
  if (data) {
    yield  put(fetchSignInUserProfileSuccess({...data}))
  } else {
    console.warn('some thing wrong on the fetch user profile')
  }

}

function* fetchSignInProfile() {
  yield takeEvery(FETCH_SIGN_IN_USER_PROFILE, fetchUserProfile)
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(fetchSignInProfile),
    fork(createUserAccount),
    fork(resetUserPassword),
    fork(confirmResetPassword),
    fork(changePassword),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(confirmEmail),
    fork(signOutUser),
  ]);

}
