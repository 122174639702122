import featherApp, {BACKEND_URL} from "../../feathersjs/feathersjs";

const uploadService = featherApp.service("uploads")

export const deleteFile = async (fileId) => {
  return uploadService.remove(fileId)
}

function convertToFormData(formInfos) {
  const formData = new FormData()
  const additionsKeys = Object.keys(formInfos || {});
  for (let i = 0; i < additionsKeys.length; i++) {
    const key = additionsKeys[i];
    formData.append(key, formInfos[key])
  }
  return formData;
}

function prepareFormData(file, additionInfos) {
  return convertToFormData({
    ...additionInfos,
    uri: file,
    fileName: file.name,
    fileType: file.type
  });
}

export const uploadFile = async (file, additionInfos) => {
  const formData = prepareFormData(file, additionInfos);

  const uploadedInfo = await uploadService.create(formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return uploadedInfo.fileId;
}

export const getFileUploadUrl =  (fileId) => {
  return `${BACKEND_URL}/files/${fileId}`
}

export const getUploadedData = async (fileId) => {
  return uploadService.get(fileId)
}
export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.replace(/^.*,/, ''));
    reader.onerror = error => reject(error);
  });
}
