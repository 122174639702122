import React, {useState} from "react";
import {Button, Col, Dropdown, Layout, Menu, message, Popover, Row, Select} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import {switchLanguage, toggleCollapsedSideNav} from "../../../appRedux/actions/Setting";
import HorizontalNav from "../HorizontalNav";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import UserProfile from "../../Sidebar/UserProfile";
import {AppLogo} from "../InsideHeader";
import {MenuOutlined} from "@ant-design/icons";

const {Header} = Layout;
export const BranchName=({fontSize=18})=><div >
  <p className={"gx-mb-1"}><strong><span style={{color:"#0f5f3b",fontSize}}>QUỸ HỌC BỔNG</span></strong></p>
  <p><span style={{color:"#0f5f3b",fontSize:fontSize+8}}><strong>TÔMA THIỆN</strong></span></p>
</div>
const BelowHeader = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const locale = useSelector(({settings}) => settings.locale);
  const {navCollapsed} = useSelector(({common}) => common);

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value)
  };

  return (
    <div className="gx-header-horizontal  gx-below-header-horizontal">
      {/*<div className="gx-header-horizontal-top">*/}
      {/*  <div className="gx-container">*/}
      {/*    <div className="gx-header-horizontal-top-flex">*/}
      {/*      <div className="gx-header-horizontal-top-left">*/}
      {/*        <i className="icon icon-alert gx-mr-3"/>*/}
      {/*        <p className="gx-mb-0 gx-text-truncate"><IntlMessages id="app.announced"/></p>*/}
      {/*      </div>*/}
      {/*      <ul className="gx-login-list">*/}
      {/*        <li>Login</li>*/}
      {/*        <li>Signup</li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Header className="gx-header-horizontal-main gx-p-0">
        <div className="gx-container ">
          <Row gutter={1} className={'gx-header-wrapper'} align={"middle"} >
            <Col xl={0} lg={0} md={20} sm={18} xs={18}>
              <MenuOutlined style={{fontSize:24}} onClick={() => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}/>
            </Col>
            <Col xl={3} lg={4} md={4} sm={6} xs={6}>
              <AppLogo/>
            </Col>
            <Col xl={9} lg={8} md={0} sm={0} xs={0}>
              <BranchName/>
            </Col>
            <Col xl={12} lg={12} md={0} sm={0} xs={0}>
              <img src="https://quyhocbongtomathien.com/wp-content/uploads/2021/12/banner-header-theme-goc.png"
                   alt=""/>
            </Col>
          </Row>

        </div>
      </Header>
      <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block gx-border-top">
        <div className="gx-container">
          <Row style={{minHeight: 70}}>
            <HorizontalNav/>
            <ul className="gx-header-notifications gx-ml-auto gx-float-right">
              {/*<li className="gx-language gx-mr-0">*/}
              {/*  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"*/}
              {/*           content={languageMenu()} trigger="click">*/}
              {/*<span className="gx-pointer gx-flex-row gx-align-items-center">*/}
              {/*  <i className={`flag flag-24 flag-${locale.icon}`}/>*/}
              {/*</span>*/}
              {/*  </Popover>*/}
              {/*</li>*/}
              <li className="gx-user-nav gx-ml-2"><UserProfile/></li>
            </ul>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default BelowHeader;
